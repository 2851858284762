import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '2m3CclvpN39GDlP9uKQXyz',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - AUSTRIA',
        slides: [
          {
            id: '3qWDAQbfQKcfHhmSzBsWkp',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2TGRQdzx811xpjSlvkbTHd/3873c37869b42aef201a1ae70496cc59/GENERIC_HPBANNERS_iPhone13_Desktop_DE.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2fwXk94fwnKaqx6gvF9e9n/ca17d75a5f6db127ed03d2b9747722d7/GENERIC_HPBANNERS_iPhone13_Mobile_DE.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'AT - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.at/de-at/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '2oLF3qys63I0FV1wciISmW',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1qO222UNrVvEnQtCKokFiN/41d268aebac0e6cc421f1f066f90c450/Copy_of_DE_Hp_Hero_desktop.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1xBZGiNYgXtHNeh3gpV8Wy/220af85821d28065a9c4b007b5f8f7de/Copy_of_DE_Hp_Hero_mobile.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'AT - LTBN Good Deals',
            },
            link: {
              href: 'https://www.backmarket.at/de-at/e/good-deals',
            },
          },
          {
            id: '6gMkJtfs0rSkVS7RqVAvpp',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/59bR6ARSZysiihGkmLBsEm/13eff0580b383ff8effcde97c3b75889/Copy_of_VISION_Desktop_DE.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/60YmDc2QQH1YXYBziiJhf3/932869c109fb172a5f6a5ec2ddff4f0e/Copy_of_VISION_Mobile_DE.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'AT - Vision ',
            },
            link: {
              href: 'https://www.backmarket.at/de-at/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '7i6QzxDtzLWLNElG9tCXRU',
      type: 'Categories',
      props: {
        title: 'Meistgesucht',
        subtitle: 'Zweite Socken sind nichts dagegen',
        categories: [
          {
            id: '285UUorwiD7KOtZNB6tbAY',
            title: 'Smartphones',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3cnra6im5fWnEyRwS71Uvg/dddfca05f9bd42e43211c69347bdfa5d/iphone_12.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12',
            },
            tagline: 'Dein persönlicher Phönix aus der Tasche.',
            link: {
              href: 'https://www.backmarket.at/de-at/l/smartphones/6c290010-c0c2-47a4-b68a-ac2ec2b64dca',
            },
          },
          {
            id: '4FtHFVXJ6xiBZpWdFEWoNN',
            title: 'Tablets',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3q7nQi6Q5LKO60xX5RPieH/c326582356ecdaa8119f7b8a07306fc3/ipad_pro_2019.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad Pro',
            },
            tagline: 'Messerscharfe Bilder mit Stil.',
            link: {
              href: 'https://www.backmarket.at/de-at/l/tablet/5a3cfa21-b588-49b1-b4e9-2636bec68ada',
            },
          },
          {
            id: '1O5oF7ovZkfIVZj9dWFcd5',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/78FXyqpTJ8sixmjT81xCNP/045b13fd70265a044824f4c06912f2d7/372386_62dea71d-6ce1-49a1-9ccc-9d42d9c99067-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook',
            },
            tagline: 'Spare mit einem refurbished MacBook. ',
            link: {
              href: 'https://www.backmarket.at/de-at/l/macbook-qwertz/e98f4b4c-97b6-46cf-8de6-2743975c248e',
            },
          },
          {
            id: '5MpHBb1watzfH2Bo6y8YDX',
            title: 'Laptops',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4rah4soJKbLIZaX0YrEIfQ/6bc5d95f288857ffaf6b43d0934b61c8/Asus_Zen_book_Pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop windows',
            },
            tagline: 'Hau in diese Tasten.',
            link: {
              href: 'https://www.backmarket.at/de-at/l/laptop-qwertz-deutsch/456f253a-69db-4880-9c4a-484d04c3b0c5',
            },
          },
          {
            id: '2n26aMnbqAlsUCM1fKj5Me',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2coRI1LOw46qbxuVTi3bJY/534b83cf2977f53eab3f944517a4fe47/AppleWatch6.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Apple Watch',
            },
            tagline: 'Die nachhaltige Alternative zur neuen Smartwatch.',
            link: {
              href: 'https://www.backmarket.at/de-at/l/apple-watch/92caf545-c033-409f-bcb0-d3cfec8af49d',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '2ECNpfkQ7W3bY8WPFBTkiM',
      type: 'Categories',
      props: {
        title: 'Andere Kategorien',
        subtitle: 'Von der Küche zur Konsole',
        categories: [
          {
            id: 'PvmWADhnPY8C98CuBVC7E',
            title: '2in1 Convertibles',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6O8RMcHLv6OWPTaYoKkgA5/cf4474c0e0022ed8670c6b2939be2d1a/Microsoft_Surface_Pro_6_Black.png',
              width: 1200,
              height: 1200,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: '2in1 Convertibles',
            },
            tagline: 'Wie neu, nur besser für unseren Planeten!',
            link: {
              href: 'https://www.backmarket.at/de-at/l/convertible-2-in-1/7141c325-52cf-4227-a3e5-16efd1c4149d',
            },
          },
          {
            id: '2TyIRPRH4HZZF6EU7Bo1nS',
            title: 'AirPods',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2POlQEIRD5qQnZPwwegZyI/2260dd924bcadbf5a91cc7a8c2072e0e/350192_dd52965c-bfd5-4668-b21f-48b27e5a7d2d.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods Pro',
            },
            tagline: 'Preise, die wie Musik in deinen Ohren klingen.',
            link: {
              href: 'https://www.backmarket.at/de-at/l/airpods/5091ca6b-c36c-4d50-b644-fb3c5bdd5f16',
            },
          },
          {
            id: '2wnFxGzajDAJofXd8qCxcM',
            title: 'Haushaltsgeräte',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/584PO33EnPcrhONChnUYFj/96f713fa6fa0c67baa6f5988cdb9778a/FR-Dyson-SiS-category-vaccum.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Aspirateur Dyson reconditionné',
            },
            tagline: "Refurbished für's ganze Haus",
            link: {
              href: 'https://www.backmarket.at/de-at/l/haushaltswaren/81006fbe-c835-4214-8e5a-be9920977276',
            },
          },
          {
            id: '74o1IoLmq0rBBEuhvlLEuQ',
            title: 'Zertifiziertes Zubehör',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/IiseEbDeAuTq5SBNiEwsO/db2bc6702e67056fa7623f964410ae02/chargeur-certifie.jpeg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Accessoires contrôlés',
            },
            tagline: 'Direkt aus der Back Market-Werkstatt zu dir nach Hause.',
            link: {
              href: '',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '60IjolKQK2OmxatVHGWrK7',
      type: 'PressCarousel',
      props: {
        technicalName: 'AT -Press Carousel',
        subtitle: 'Diese Medien berichten über uns!',
        title: 'Back Market in der Presse',
        articles: [
          {
            id: '6e3mbs5uZlAVs748khvH7U',
            text: 'Refurbished-Marktplatz Back Market sammelt 276 Millionen Euro ein.',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/662Nez2Sk35w25fZmRSA97/5cabdde0bf7180916f4ff4b40f122de8/trending-topics-300x200.png',
              width: 300,
              height: 200,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/662Nez2Sk35w25fZmRSA97/5cabdde0bf7180916f4ff4b40f122de8/trending-topics-300x200.png',
              widthMobile: 300,
              heightMobile: 200,
              alt: 'Trending topics',
            },
            link: {
              href: 'https://www.trendingtopics.eu/refurbished-marktplatz-back-market-sammelt-276-millionen-euro-ein/',
            },
            publicationDate: '05/2021',
          },
          {
            id: 'dSCFdmbAl7DHccp5bqc8w',
            text: 'Back Market Gründer über das 276 Millionen Euro Investment und die Expansionspläne für Österreich.',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2M80vNHjSp2yXzRJt4kbC/b78e54e95eac1c354fa84793210dc5d2/brutkasten-585x200.png',
              width: 585,
              height: 200,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2M80vNHjSp2yXzRJt4kbC/b78e54e95eac1c354fa84793210dc5d2/brutkasten-585x200.png',
              widthMobile: 585,
              heightMobile: 200,
              alt: 'brutkasten',
            },
            link: {
              href: 'https://brutkasten.com/back-market-interview-thibaud-hug-de-larauze/',
            },
            publicationDate: '05/2021',
          },
          {
            id: '602W3VGeOiHrCmhFSoq0no',
            text: 'iPhone bei Back Market kaufen: Darauf sollten Sie achten',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3Sya5IzikaYjfzXrgYwSrY/b8c8ab6668fbce73fe3b7c45dd8e174b/Chiponline.svg.png',
              width: 706,
              height: 300,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3Sya5IzikaYjfzXrgYwSrY/b8c8ab6668fbce73fe3b7c45dd8e174b/Chiponline.svg.png',
              widthMobile: 706,
              heightMobile: 300,
              alt: 'Chip Online',
            },
            link: {
              href: 'https://praxistipps.chip.de/iphone-bei-back-market-kaufen-darauf-sollten-sie-achten_130341',
            },
            publicationDate: '05/2021',
          },
          {
            id: '4MNZVzPccadvuIR0IWDxQi',
            text: 'Inside Back Market: Reparieren, aufpolieren, wiederverkaufen',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/662Nez2Sk35w25fZmRSA97/5cabdde0bf7180916f4ff4b40f122de8/trending-topics-300x200.png',
              width: 300,
              height: 200,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/662Nez2Sk35w25fZmRSA97/5cabdde0bf7180916f4ff4b40f122de8/trending-topics-300x200.png',
              widthMobile: 300,
              heightMobile: 200,
              alt: 'Trending topics',
            },
            link: {
              href: 'https://www.trendingtopics.eu/inside-back-market-reparieren-aufpolieren-wiederverkaufen-ein-milliardenmarkt/',
            },
            publicationDate: '04/2021',
          },
          {
            id: '6jtXl1hPYRbnYdhIPLvCri',
            text: 'Start-up für wiederaufbereitete Elektronik Back Market startet in Österreich',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1vVZ20FkWGAkbho4JpWPUo/0b8b8128c76ee14951be89d0516d16ca/internetworld-logo.jpg',
              width: 379,
              height: 119,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1vVZ20FkWGAkbho4JpWPUo/0b8b8128c76ee14951be89d0516d16ca/internetworld-logo.jpg',
              widthMobile: 379,
              heightMobile: 119,
              alt: 'Internet world business',
            },
            link: {
              href: 'https://www.internetworld.at/e-commerce/online-marktplatz/start-up-wiederaufbereitete-elektronik-back-market-startet-in-oesterreich-2533739.html',
            },
            publicationDate: '05/2020',
          },
          {
            id: 'fPvJuAjbrWkxvXjlLeJSX',
            text: 'Smartphones: Neuwertig vs. gebraucht',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7krdJLOtI9QRJJj7ud3vS1/6fc0d438fdaff9f2581aa38878d2e88a/VKI.png',
              width: 177,
              height: 285,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7krdJLOtI9QRJJj7ud3vS1/6fc0d438fdaff9f2581aa38878d2e88a/VKI.png',
              widthMobile: 177,
              heightMobile: 285,
              alt: 'Vki',
            },
            link: {
              href: 'https://www.internetworld.at/e-commerce/online-marktplatz/start-up-wiederaufbereitete-elektronik-back-market-startet-in-oesterreich-2533739.html',
            },
            publicationDate: '03/2021',
          },
          {
            id: '43ciafgkEX6oblH887RKCl',
            text: 'Diese Plattformen kaufen Ihr altes Smartphone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2vxiami0ZwiJAvO9Dt70Da/28ec16912384de02455a0236b088df67/welt-de-vector-logo.png',
              width: 540,
              height: 300,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2vxiami0ZwiJAvO9Dt70Da/28ec16912384de02455a0236b088df67/welt-de-vector-logo.png',
              widthMobile: 540,
              heightMobile: 300,
              alt: 'welt',
            },
            link: {
              href: 'https://www.welt.de/wirtschaft/article206248007/Second-Hand-Smartphones-Der-Markt-waechst-auch-in-Deutschland.html',
            },
            publicationDate: '03/2020',
          },
          {
            id: '3vyL4wFiPESlCxkxUBmTwB',
            text: 'Nachhaltiger leben: Mit diesen 13 Alltagsentscheidungen verbesserst du die Welt',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1dUMaS4MUg7cwOUIND77mr/af45d135b45e8cf346edbf338c376f2c/instyle.webp',
              width: 600,
              height: 300,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1dUMaS4MUg7cwOUIND77mr/af45d135b45e8cf346edbf338c376f2c/instyle.webp',
              widthMobile: 600,
              heightMobile: 300,
              alt: 'instyle',
            },
            link: {
              href: 'https://www.instyle.de/lifestyle/nachhaltiger-leben-alltagsentscheidungen',
            },
            publicationDate: '02/2020',
          },
          {
            id: '24PQtTDPBEWMRaHCRHGBAO',
            text: 'Refurbished-Startup Back Market holt sich 110 Mio. Euro bei Investoren',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/662Nez2Sk35w25fZmRSA97/5cabdde0bf7180916f4ff4b40f122de8/trending-topics-300x200.png',
              width: 300,
              height: 200,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/662Nez2Sk35w25fZmRSA97/5cabdde0bf7180916f4ff4b40f122de8/trending-topics-300x200.png',
              widthMobile: 300,
              heightMobile: 200,
              alt: 'Trending topics',
            },
            link: {
              href: 'https://www.trendingtopics.eu/back-market-investment-2020/',
            },
            publicationDate: '05/2020',
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4NnmDFg8m9OY4kri0oh5FT',
      type: 'Faq',
      props: {
        title: 'Nachschlag gefällig?',
        subtitle: '4 Fragen, die uns immer wieder gestellt werden',
        questions: [
          {
            id: '2kHedI8dT6YSr4wvjFoPM6',
            title:
              'Woher weiß ich, dass mein Gerät nicht nach einem Monat den Geist aufgibt?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "Wir geben 110 %, damit deine Technik hält, was wir versprechen. Bei uns dürfen nur Händler:innen verkaufen, die ein strenges Prüfverfahren bestehen. Und dann kaufen wir auch noch heimlich selbst bei ihnen ein. Wie Ninjas ...Qualitätsninjas! 30 Tage Zeit hast du dann auch noch, um es dir anders zu überlegen. 12 Monate Garantie gibt's sowieso.",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '33RU8TQzmQuFhaqDlnwfcw',
            title: 'Was ist der Unterschied zwischen erneuert und neu?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Von außen sieht ein erneuertes Smartphone aus wie neu. Funktioniert auch so. Aber wahre Schönheit kommt von innen. Erneuerte Smartphones, Tablets, Laptops und Computer belasten die Umwelt deutlich weniger als Neuware.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6LSrvhwIHARVT6if0eFEo',
            title: 'Was verkauft ihr sonst noch so?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Wir können mehr als Smartphones. Von Spielekonsolen über Lockenstäbe bis zu Babyfonen und Kaffeemaschinen (zwinker, zwinker, liebe Jungeltern), haben wir wahrscheinlich genau, was du suchst.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1WxvpwMpGkKXtATcAgctqF',
            title:
              'War der T-800 in Terminator 2: Tag der Abrechnung quasi "runderneuert“?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Da müssten wir erstmal Skynet durch unsere Qualitätsprüfung schicken. Aber hey, Arnie hat gesagt “I’ll be back”, oder? Klingelt da was?',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.at/de-at/help',
          },
          label: 'Antworten finden',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Home',
  meta: {
    title: 'Nachhaltige Technik zum günstigen Preis.',
    description:
      'Der zuverlässige Marktplatz für gebrauchte & refurbished Handys, Tablets, Laptops und mehr Elektronik. Kaufe dein nächstes Gerät günstig und sicher mit 36 Monate Garantie.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/801c9c79d77e4e49afce9ce4adc04133/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Nachhaltige & refurbished Technik zum günstigen Preis',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
